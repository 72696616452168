import { Moment } from 'moment';

export default class ReceiptDetail {
  id: any = null;
  ccm: any = null;
  total: any = null;
  date: any | null = null;
  transaction_code: any | null = null;
  payment_type_id: any = null;
  card_number: any = null;
  status: any = null;
  file_source: any = null;
  operator_verifying_at: any = null;
  tenant: any = null;
  mall: any = null;
  payment_type: any = null;
  location: any = null;
  member: any = null;
  operator: any = null;
  verifier: any = null;
  reason: any = null;
  time: any = null;
  created_at: any = null;
}
