import ScoringActionType from '../contracts/ScoringActionType';
import ScoringState from '../contracts/ScoringState';
import ScoringAction from '../contracts/ScoringAction';

const INITIAL_STATE: ScoringState = new ScoringState();

const handler = (state = INITIAL_STATE, action: ScoringAction) => {
  switch (action.type) {
    case ScoringActionType.SCORING_LIST_SUCCESS:
      return {
        ...state,
        scoringList: action.scoringList
      };
    case ScoringActionType.SCORING_DETAIL_SUCCESS:
      return {
        ...state,
        scoringDetail: action.scoringDetail
      };
    case ScoringActionType.DOWNLOAD_EXCEL_SUCCESS:
      return {
        ...state,
        excelData: action.excelData
      };
    case ScoringActionType.DOWNLOAD_CSV_SUCCESS:
      return {
        ...state,
        csvData: action.csvData
      };
    default:
      return state;
  }
};

export default handler;
