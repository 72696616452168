import TablePagination from "../../utils/contracts/TablePagination";
import TenantState from "../contracts/tenant/TenantState";
import DashboardActionType from "../contracts/DashboardActionType";
import { Moment } from 'moment';
import BarChart from "../contracts/BarChart";
import PieChart from "../contracts/PieChart";

export function mallTenantReport(mallId:any, startDate:Moment|null, endDate:Moment|null, pagination:TablePagination = new TablePagination,) {
  return { type: DashboardActionType.MALL_TENANT_REPORT, mallId, startDate, endDate, pagination }
}
export function mallTenantReportSuccess(tenantState:TenantState) {
  return { type: DashboardActionType.MALL_TENANT_REPORT_SUCCESS, tenantState }
}
export function mallTenantReportFailed() {
  return { type: DashboardActionType.MALL_TENANT_REPORT_FAILED }
}
export function barChart(startDate:Moment|null, endDate:Moment|null) {
  return { type: DashboardActionType.BAR_CHART, startDate, endDate}
}
export function barChartSuccess(barChart:Array<BarChart>) {
  return { type: DashboardActionType.BAR_CHART_SUCCESS, barChart }
}
export function barChartFailed() {
  return { type: DashboardActionType.BAR_CHART_FAILED }
}
export function pieChart(startDate:Moment|null, endDate:Moment|null) {
  return { type: DashboardActionType.PIE_CHART, startDate, endDate}
}
export function pieChartSuccess(pieChart:Array<PieChart>) {
  return { type: DashboardActionType.PIE_CHART_SUCCESS, pieChart }
}
export function pieChartFailed() {
  return { type: DashboardActionType.PIE_CHART_FAILED }
}