import { takeEvery, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import ResponseApi from "../../utils/contracts/ReponseApi";
import CollectionAction from "../contracts/CollectionAction";
import CollectionActionType from "../contracts/CollectionActionType";
import * as collectionApi from "../api/collection";
import {
  advancedSearchDataSuccess,
  advancedSearchDataFailed,
  collectionListSuccess,
  collectionListFailed,
  receiptDataSuccess,
  receiptDataFailed,
  tenantListDataSuccess,
  tenantListDataFailed,
  receiptVerificationSuccess,
  receiptVerificationFail,
  downloadExcelSuccess,
  downloadExcelFailed,
  downloadCsvSuccess,
  downloadCsvFailed,
} from "../actions/collection";
import history from "../../../history";
import { showLoading, hideLoading } from "../../utils/loading/actions/loading";

function* advancedSearchData(action: CollectionAction) {
  let response: ResponseApi = yield collectionApi.advancedSearchData();

  if (response.responseCode === 200) {
    yield put(hideLoading());
    yield put(advancedSearchDataSuccess(response.responseData));
  } else {
    yield put(showLoading());
    yield put(advancedSearchDataFailed());
    yield toast.error(response.responseMessage);
  }
}

function* collectionList(action: CollectionAction) {
  let response: ResponseApi = yield collectionApi.collectionList(
    action.pagination,
    action.advancedSearchForm
  );

  if (response.responseCode === 200) {
    yield put(hideLoading());
    yield put(collectionListSuccess(response.responseData));
  } else {
    yield put(showLoading());
    yield put(collectionListFailed());
    yield toast.error(response.responseMessage);
  }
}

function* receiptData(action: CollectionAction) {
  let receiptDetail: ResponseApi = yield collectionApi.receiptDetail(
    action.receiptId
  );

  let tenantList: ResponseApi = yield collectionApi.tenantList(
    receiptDetail.responseData.mall ? receiptDetail.responseData.mall.id : ""
  );
  let mallList: ResponseApi = yield collectionApi.mallList();
  let paymentTypeList: ResponseApi = yield collectionApi.paymentTypeList();
  let receiptStatusList: ResponseApi = yield collectionApi.receiptStatusList();
  let receiptReasonList: ResponseApi = yield collectionApi.receiptReasonList();

  let responseData = {
    tenants: tenantList.responseData,
    malls: mallList.responseData,
    payment_types: paymentTypeList.responseData,
    statuses: receiptStatusList.responseData,
    reasons: receiptReasonList.responseData,
  };
  if (receiptDetail.responseCode === 200) {
    yield put(receiptDataSuccess(receiptDetail.responseData, responseData));
  } else {
    yield put(receiptDataFailed());
    yield toast.error(receiptDetail.responseMessage);
  }
}

function* tenantListData(action: CollectionAction) {
  let response: ResponseApi = yield collectionApi.tenantList(action.mallId);
  if (response.responseCode === 200) {
    yield put(hideLoading());
    yield put(tenantListDataSuccess(response.responseData));
  } else {
    yield put(showLoading());
    yield put(tenantListDataFailed());
    yield toast.error(response.responseMessage);
  }
}

function* receiptVerification(action: CollectionAction) {
  let response: ResponseApi = yield collectionApi.receiptVerification(
    action.receiptId,
    action.verificationForm
  );

  if (response.responseCode === 200) {
    yield put(hideLoading());
    yield put(receiptVerificationSuccess());
    yield toast.success("Success to verify a receipt.");
    yield history.push(`/dashboard/collections`);
  } else {
    yield put(showLoading());
    yield put(receiptVerificationFail());
    yield toast.error(response.responseMessage);
  }
}

function* downloadExcel(action: CollectionAction) {
  let response: ResponseApi = yield collectionApi.downloadExcel(
    action.advancedSearchForm
  );

  if (response.responseCode === 200) {
    yield put(hideLoading());
    yield put(downloadExcelSuccess(response.responseData));
    yield (window.location.href = response.responseData.downloadUrl);
  } else {
    yield put(showLoading());
    yield put(downloadExcelFailed());
    yield toast.error(response.responseMessage);
  }
}

function* downloadCsv(action: CollectionAction) {
  let response: ResponseApi = yield collectionApi.downloadCsv(
    action.advancedSearchForm
  );

  if (response.responseCode === 200) {
    yield put(hideLoading());
    yield put(downloadCsvSuccess(response.responseData));
    yield (window.location.href = response.responseData.downloadUrl);
  } else {
    yield put(showLoading());
    yield put(downloadCsvFailed());
    yield toast.error(response.responseMessage);
  }
}

export default [
  takeEvery(CollectionActionType.ADVANCED_SEARCH_DATA, advancedSearchData),
  takeEvery(CollectionActionType.COLLECTION_LIST, collectionList),
  takeEvery(CollectionActionType.RECEIPT_DATA, receiptData),
  takeEvery(CollectionActionType.TENANT_LIST_DATA, tenantListData),
  takeEvery(CollectionActionType.RECEIPT_VERIFICATION, receiptVerification),
  takeEvery(CollectionActionType.DOWNLOAD_EXCEL, downloadExcel),
  takeEvery(CollectionActionType.DOWNLOAD_CSV, downloadCsv),
];
