import React from "react";
import { connect } from "react-redux";

import {
  Col,
  Container,
  Row,
} from "reactstrap";
import { ReducerState } from "../../../../registrator";
import Header from "../../../../components/Headers/Header";
import Chart from "./Chart";
import Loading from "../../../utils/loading/components/Loading";

interface DerivedProps {
}

interface States {
}

class User extends React.Component<DerivedProps, States> {
  render() {
    return (
      <>
        <Header
          breadcrumbs={[
          ]}
        />
        <Container className="mt--7" fluid>
          <Row>
            <Col xs="12">
              <Loading />
              <Chart />
            </Col>
            <Col xs="12" className="mt-4">
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}


const mapStatesToProps = (state: ReducerState) => ({
})

const mapActionsToProps = {
}

export default connect(mapStatesToProps, mapActionsToProps)(User);
