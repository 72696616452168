import React from "react";
import { Route, Switch, RouteComponentProps } from "react-router-dom";
import {
  Container,
} from "reactstrap";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import Sidebar from "../components/Sidebar/Sidebar";

import { ToastContainer } from "../modules/utils/components/Toast";

import routes, { menus } from "../routes";
import { ReducerState } from "../registrator";
import { connect } from "react-redux";
import Loading from "../../src/modules/utils/loading/components/Loading";

interface DerivedProps extends RouteComponentProps {
}

interface State {
}

class Admin extends React.Component<DerivedProps, State> {
  componentDidUpdate(e:any) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement!.scrollTop = 0;
  }

  render() {
    return (
      <>
        <ToastContainer />
        <Loading />
        <Sidebar
          {...this.props}
          menus={menus}
          logo={{
            innerLink: "/",
            imgSrc: require("../assets/img/brand/logo-asri-color-trans-vector.svg"),
            imgAlt: '...',
          }}
        />
        <div className="main-content">
          <AdminNavbar
            {...this.props}
            brandText={'ASRI'}
          />
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                exact={true}
                path={route.path}
                component={route.Component}
              />
            ))}
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default Admin
