import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { ReducerState } from "../../../registrator";
import AuthState from "../contracts/AuthState";

export default function GuestMiddleware(Component: any) {
  interface DerivedProps {
    auth: AuthState;
  }

  class GuestMiddleware extends React.Component<DerivedProps> {
    render() {
      return this.props.auth.isAuthenticated ? (
        <Redirect to="/" />
      ) : (
        <Component {...this.props} />
      );
    }
  }

  const mapStatesToProps = (state: ReducerState) => ({
    auth: state.auth,
  });

  return connect(mapStatesToProps)(GuestMiddleware);
}
