import {createStore, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import registrator from './registrator'

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  registrator.reducers,
  composeWithDevTools(
    applyMiddleware(sagaMiddleware),
  )
)

sagaMiddleware.run(registrator.sagas)

export default store