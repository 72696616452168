import React from "react";
import { Route, Switch } from "react-router-dom";
import { 
  Container,
  Row,
} from "reactstrap";
import { ToastContainer } from "../modules/utils/components/Toast";
import routes from "../routes";
import Loading from "../../src/modules/utils/loading/components/Loading";

class Auth extends React.Component {
  render() {
    return (
      <>
        <ToastContainer />
        <Loading />
        <div className="main-content auth-content">
          <Container className="mt-8 pb-5">
            <Row className="justify-content-center">
              <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  exact={true}
                  path={route.path}
                  component={route.Component}
                />
              ))}
              </Switch>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Auth;
