import { takeEvery, put, take } from 'redux-saga/effects'
import * as authApi from '../api/auth'
import {
  authUserDetail as authUserDetailAction,
  authLoginSuccess,
  authLoginFailed as authLoginFail,
  authUserDetailDone,
  authUserDetailFail,
  authLogout,
  authRefreshTokenDone
} from '../actions/auth'
import AuthAction from '../contracts/AuthAction';
import AuthActionType from '../contracts/AuthActionType';
import { toast } from 'react-toastify';
import ResponseApi from '../../utils/contracts/ReponseApi';
import { hideLoading } from '../../utils/loading/actions/loading';

function* authLogin(action: AuthAction) {
  let response: ResponseApi = yield authApi.authLogin(action.loginForm)

  if (response.responseCode === 200) {
    yield put(authLoginSuccess(response.responseData))
  }
  else {
    yield put(authLoginFail())
    yield toast.error(response.responseMessage)
  }
}

function* authUserDetail(action: AuthAction): any {
  let userDetailResponse = yield authApi.authUserDetail()

  // Refreshing User Token
  if (action.shouldRefreshToken && userDetailResponse === 'INVALID_TOKEN') {
    let refreshTokenResponse: ResponseApi = yield authApi.authRefreshToken()

    if (refreshTokenResponse.responseCode === 200) {
      yield put(authRefreshTokenDone(refreshTokenResponse.responseData))
    }
    yield put(authUserDetailAction(false))
  }
  else if (userDetailResponse.responseCode === 200) {
    yield put(authUserDetailDone(userDetailResponse.responseData))
  }
  else {
    yield put(hideLoading())
    yield put(authUserDetailFail())
    yield put(authLogout())
  }
}

export default [
  takeEvery(AuthActionType.AUTH_LOGIN, authLogin),
  takeEvery(AuthActionType.AUTH_USER_DETAIL, authUserDetail),
]