export enum ScoringActionType {
  SCORING_LIST = 'SCORING_LIST',
  SCORING_LIST_SUCCESS = 'SCORING_LIST_SUCCESS',
  SCORING_LIST_FAILED = 'SCORING_LIST_FAILED',

  SCORING_DETAIL = 'SCORING_DETAIL',
  SCORING_DETAIL_SUCCESS = 'SCORING_DETAIL_SUCCESS',
  SCORING_DETAIL_FAILED = 'SCORING_DETAIL_FAILED',

  DOWNLOAD_EXCEL = 'DOWNLOAD_SCORINGS_EXCEL',
  DOWNLOAD_EXCEL_SUCCESS = 'DOWNLOAD_SCORINGS_EXCEL_SUCCESS',
  DOWNLOAD_EXCEL_FAILED = 'DOWNLOAD_SCORINGS_EXCEL_FAILED',
  DOWNLOAD_CSV = 'DOWNLOAD_SCORINGS_CSV',
  DOWNLOAD_CSV_SUCCESS = 'DOWNLOAD_SCORINGS_CSV_SUCCESS',
  DOWNLOAD_CSV_FAILED = 'DOWNLOAD_SCORINGS_CSV_FAILED'
}

export default ScoringActionType;
