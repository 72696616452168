import { takeEvery, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import ResponseApi from '../../utils/contracts/ReponseApi';
import ScoringAction from '../contracts/ScoringAction';
import ScoringActionType from '../contracts/ScoringActionType';
import * as scoringApi from '../api/scoring';
import {
  scoringListSuccess,
  scoringListFailed,
  scoringDetailSuccess,
  scoringDetailFailed,
  downloadExcelSuccess,
  downloadExcelFailed,
  downloadCsvSuccess,
  downloadCsvFailed
} from '../actions/scoring';
import { showLoading, hideLoading } from "../../utils/loading/actions/loading";

function* scoringList(action: ScoringAction) {
  let response: ResponseApi = yield scoringApi.scoringList(
    action.startDate,
    action.endDate,
    action.pagination
  );

  if (response.responseCode === 200) {
    yield put(scoringListSuccess(response.responseData));
  } else {
    yield put(scoringListFailed());
    yield toast.error(response.responseMessage);
  }
}

function* scoringDetail(action: ScoringAction) {
  let response: ResponseApi = yield scoringApi.scoringDetail(
    action.scoringId,
    action.startDate,
    action.endDate,
    action.pagination
  );

  if (response.responseCode === 200) {
    yield put(scoringDetailSuccess(response.responseData));
  } else {
    yield put(scoringDetailFailed());
    yield toast.error(response.responseMessage);
  }
}

function* downloadExcel(action: ScoringAction) {
  let response: ResponseApi = yield scoringApi.downloadExcel(
    action.startDate,
    action.endDate,
    action.search,
  );

  if (response.responseCode === 200) {
    yield put(hideLoading());
    yield put(downloadExcelSuccess(response.responseData));
    yield (window.location.href = response.responseData.downloadUrl);
  } else {
    yield put(showLoading());
    yield put(downloadExcelFailed());
    yield toast.error(response.responseMessage);
  }
}

function* downloadCsv(action: ScoringAction) {
  let response: ResponseApi = yield scoringApi.downloadCsv(
    action.startDate,
    action.endDate,
    action.search,
  );

  if (response.responseCode === 200) {
    yield put(hideLoading());
    yield put(downloadCsvSuccess(response.responseData));
    yield (window.location.href = response.responseData.downloadUrl);
  } else {
    yield put(showLoading());
    yield put(downloadCsvFailed());
    yield toast.error(response.responseMessage);
  }
}

export default [
  takeEvery(ScoringActionType.SCORING_LIST, scoringList),
  takeEvery(ScoringActionType.SCORING_DETAIL, scoringDetail),
  takeEvery(ScoringActionType.DOWNLOAD_EXCEL, downloadExcel),
  takeEvery(ScoringActionType.DOWNLOAD_CSV, downloadCsv)
];
