import AuthState from '../contracts/AuthState';
import AuthStorage from '../contracts/AuthStorage';
import AuthAction from '../contracts/AuthAction';
import AuthActionType from '../contracts/AuthActionType';

const INITIAL_STATE:AuthState = new AuthState

const handler = (state = INITIAL_STATE, action: AuthAction) => {
  switch (action.type) {
    case AuthActionType.AUTH_LOGIN_SUCCESS:
      localStorage.setItem(AuthStorage.TOKEN, action.tokenData.token)
      localStorage.setItem(AuthStorage.REFRESH_TOKEN, action.tokenData.refreshToken)
      return { ...state, isAuthenticated: true }
    case AuthActionType.AUTH_LOGOUT:
      localStorage.removeItem(AuthStorage.TOKEN)
      localStorage.removeItem(AuthStorage.REFRESH_TOKEN)
      return { ...state, ...INITIAL_STATE, isAuthenticated: false }
    case AuthActionType.AUTH_LOGIN_FAILED:
        localStorage.removeItem(AuthStorage.TOKEN)
        localStorage.removeItem(AuthStorage.REFRESH_TOKEN)
    break;
    case AuthActionType.AUTH_USER_DETAIL_DONE:
      return {
        ...state,
        userData: {
          ...action.userData,
          roleCodes: ['USER']
        }
      }

    case AuthActionType.AUTH_REFRESH_TOKEN_DONE:
      localStorage.setItem(AuthStorage.TOKEN, action.tokenData.token)
      localStorage.setItem(AuthStorage.REFRESH_TOKEN, action.tokenData.refreshToken)
      break;
    
    default:
      return state
  }
}

export default handler