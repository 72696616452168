import { Moment } from 'moment';

export default class AdvancedSearchForm {
  location_id: any = null;
  tenant_id: any = null;
  mall_id: any = null;
  member_name: any = null;
  operator_name: any = null;
  ccm: string = '';
  payment_type_id: any = null;
  status_id: any = null;
  reason_id: any = null;
  is_verified: number = 0;
  submitted_period_start: Moment | null = null;
  submitted_period_end: Moment | null = null;
}
