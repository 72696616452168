import { fetchApi } from '../../utils/fetch';
import TablePagination from '../../utils/contracts/TablePagination';
import { Moment } from "moment";

export function mallTenantReport(mallId:any, 
  pagination:TablePagination, 
  startDate:Moment|null, 
  endDate:Moment|null) {
  let queryString = `?page=${pagination.page}
    &size=${pagination.size}
    &search=${pagination.search}`;

  if (startDate && endDate) {
    queryString = `?page=${pagination.page}&size=${pagination.size}&search=${pagination.search}&startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`;
  }
  return fetchApi(`/dashboard/mall/${mallId}/tenant-report${queryString}`, {
    method: 'GET',
    headers: {
      'Authorization': true,
    },
  });
}

export function barChart(startDate:Moment|null, endDate:Moment|null) {

  let queryString = ``;

  if (startDate && endDate) {
    queryString = `?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`;
  }  

  return fetchApi(`/dashboard/mall/bar-chart${queryString}`, {
      method: 'GET',
      headers: {
      'Authorization': true,
    },
  });

}

export function pieChart(startDate:Moment|null, endDate:Moment|null) {

  let queryString = ``;

  if (startDate && endDate) {
    queryString = `?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`;
  }  

  return fetchApi(`/dashboard/mall/pie-chart${queryString}`, {
      method: 'GET',
      headers: {
      'Authorization': true,
    },
  });

}
