/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

interface InjectedProp {
  breadcrumbs ?  : Array<{
    link: string|'ACTIVE'
    text: string
  }>
}

class Header extends React.Component<InjectedProp> {
  render() {
    return (
      <>
        <div className="header bg-gradient-primary pb-8 pt-5 pt-md-7">
          {this.props.breadcrumbs && this.props.breadcrumbs.length > 0 && (
            <Container fluid>
              <Row>
                <Col>
                  <Breadcrumb listClassName='bg-white'>
                    {this.props.breadcrumbs.map((item, index) => (
                      item.link == 'ACTIVE'
                      ? <BreadcrumbItem key={index} active>{item.text}</BreadcrumbItem>
                      : <BreadcrumbItem key={index}><Link to={item.link}>{item.text}</Link></BreadcrumbItem>
                    ))}
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </>
    );
  }
}

export default Header;
