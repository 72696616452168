import React from "react";
import { connect } from "react-redux";
import { Col, Container, Card, CardBody, CardHeader, Row } from "reactstrap";
import DateRangePicker from "../../../utils/components/DatePicker/DateRangePicker";
import Header from "../../../../components/Headers/Header";
import { ReducerState } from "../../../../registrator";
import DateRangeFilter from "../../contracts/DateRangeFilter";
import Table from "../../../utils/components/Table/Table";
import TablePagination from "../../../utils/contracts/TablePagination";
import { mallTenantReport } from "../../actions/dashboard";
import { RouteComponentProps } from "react-router";
import DashboardState from "../../contracts/DashboardState";
import NumberFormat from "react-number-format";

interface DerivedProps
  extends RouteComponentProps<{
    mallId: any;
  }> {
  mallTenantReport: typeof mallTenantReport;
  dashboard: DashboardState;
}

interface States {
  dateRange: DateRangeFilter;
}

class Tenant extends React.Component<DerivedProps, States> {
  state: States = {
    dateRange: new DateRangeFilter(),
  };

  componentDidMount() {
    const { match, mallTenantReport } = this.props;
    const { dateRange } = this.state;
    mallTenantReport(
      match.params.mallId,
      dateRange.startDate,
      dateRange.endDate
    );
  }

  handlePageChange = (pagination: TablePagination) => {
    const { match, mallTenantReport } = this.props;
    const { dateRange } = this.state;

    mallTenantReport(
      match.params.mallId,
      dateRange.startDate,
      dateRange.endDate,
      pagination
    );
  };

  onDatesChange = ({ startDate, endDate }: any) => {
    const { match, mallTenantReport } = this.props;

    this.setState((state: States) => ({
      dateRange: {
        ...state.dateRange,
        startDate,
        endDate,
      },
    }));

    mallTenantReport(match.params.mallId, startDate, endDate);
  };

  onFocusChange = (focusedInput: any) => {
    this.setState((state: States) => ({
      dateRange: {
        ...state.dateRange,
        focusedInput,
      },
    }));
  };

  render() {
    const { match, dashboard } = this.props;
    const { dateRange } = this.state;
    if (dashboard.tenant) {
      return (
        <>
          <Header
            breadcrumbs={[
              { link: "/dashboard", text: "Dashboard" },
              {
                link: `/dashboard/${match.params.mallId}/tenant-report`,
                text: `${dashboard.tenant.mall.name} Tenant Report`,
              },
            ]}
          />
          <Container className="mt--7" fluid>
            <Row>
              <Col xs="12">
                <Card className="shadow">
                  <CardHeader>
                    <h2 className="mb-0 text-left">
                      Sales Tenant {dashboard.tenant.mall.name}
                    </h2>
                    <h3 className="mb-0 text-left">
                      {dateRange.startDate &&
                        dateRange.startDate.format("DD MMMM YYYY")}{" "}
                      -{" "}
                      {dateRange.endDate &&
                        dateRange.endDate.format("DD MMMM YYYY")}
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <DateRangePicker
                        startDateId="TRX_START_DATE"
                        startDate={dateRange.startDate}
                        endDateId="TRX_END_DATE"
                        endDate={dateRange.endDate}
                        onDatesChange={this.onDatesChange}
                        focusedInput={dateRange.focusedInput}
                        onFocusChange={this.onFocusChange}
                        numberOfMonths={1}
                        minimumNights={0}
                        isOutsideRange={() => false}
                        readOnly
                      />
                    </div>
                    <Table
                      config={{
                        shouldShowSearch: true,
                      }}
                      pagination={dashboard.tenant.tenants.pagination}
                      onSizeChange={this.handlePageChange}
                      onSearchChange={this.handlePageChange}
                      onPageChange={this.handlePageChange}
                      Thead={
                        <tr>
                          <th className="text-center" scope="col">
                            Tenants
                          </th>
                          <th className="text-center" scope="col">
                            Valid (Rp)
                          </th>
                          <th className="text-center" scope="col">
                            Invalid (Rp)
                          </th>
                          <th className="text-center" scope="col">
                            Double Entry (Rp)
                          </th>
                        </tr>
                      }
                      Tbody={dashboard.tenant.tenants.items.map(
                        (item, index) => (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td className="text-right">
                              <NumberFormat
                                value={
                                  item.report != null ? item.report.valid : ""
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={""}
                              />
                            </td>
                            <td className="text-right">
                              <NumberFormat
                                value={
                                  item.report != null ? item.report.invalid : ""
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={""}
                              />
                            </td>
                            <td className="text-right">
                              <NumberFormat
                                value={
                                  item.report != null
                                    ? item.report.double_entry
                                    : ""
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={""}
                              />
                            </td>
                          </tr>
                        )
                      )}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    } else {
      return <p>Loading...</p>;
    }
  }
}

const mapStatesToProps = (state: ReducerState) => ({
  dashboard: state.dashboard,
});

const mapActionsToProps = {
  mallTenantReport,
};

export default connect(mapStatesToProps, mapActionsToProps)(Tenant);
