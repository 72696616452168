import LoadingActionType from "../contracts/LoadingActionType"
import LoadingState from "../contracts/LoadingState"
import LoadingAction from "../contracts/LoadingAction"

const INITIAL_STATE: LoadingState = {
  isOpen: false
}

const handler = (state = INITIAL_STATE, action: LoadingAction) => {
  switch (action.type) {
    case LoadingActionType.SHOW:
      return { ...state, isOpen: true }
    case LoadingActionType.HIDE:
      return { ...state, isOpen: false }
  
    default:
      return state
  }
}

export default handler