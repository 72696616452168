import React from 'react';
import { connect } from 'react-redux';

import { Col, Container, Row } from 'reactstrap';
import Header from '../../../../components/Headers/Header';
import { ReducerState } from '../../../../registrator';
import AdvancedSearch from './AdvancedSearch';
import CollectionList from './CollectionList';

interface DerivedProps {}

interface States {}

class Collection extends React.Component<DerivedProps, States> {
  render() {
    return (
      <>
        <Header breadcrumbs={[]} />
        <Container className="mt--7" fluid>
          <Row>
            <Col xs="12">
              <AdvancedSearch />
            </Col>
            <Col xs="12" className="mt-4">
              <CollectionList />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStatesToProps = (state: ReducerState) => ({});

const mapActionsToProps = {};

export default connect(mapStatesToProps, mapActionsToProps)(Collection);
