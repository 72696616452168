/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  FormText
} from "reactstrap";
import { connect } from "react-redux";
import { ReducerState } from "../../../../registrator";
import { authLogin } from "../../actions/auth";
import { Control, Form, Errors } from 'react-redux-form';
import validator from 'validator';
import LoginForm from "../../contracts/LoginForm";

interface DerivedProps {
  authLogin: typeof authLogin
}

class Login extends React.Component<DerivedProps> {
  handleSubmit = (loginForm: LoginForm) => {
    this.props.authLogin(loginForm);
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary bg-white-transparent shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center mb-2">
                <img className="auth-logo" src={require("../../../../assets/img/brand/logo-asri-color-trans-vector.svg")} />
              </div>
              <div className="text-center text-muted mb-4">
                <small>Sign in to continue</small>
              </div>
              <Form
                model="auth.loginForm"
                onSubmit={this.handleSubmit}
                validators={{
                  email: {
                    isRequired: (val) => val,
                    isEmail: (val) => !val || validator.isEmail(val)
                  },
                  password: {
                    isRequired: (val) => val,
                  }
                }}
              >
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Control.text
                      placeholder="Email"
                      model=".email"
                      component={Input}
                    />
                  </InputGroup>
                  <FormText color="danger">
                    <Errors
                      model=".email"
                      show={{touched: true, focus: false}}
                      messages={{
                        isRequired: 'Email is required. ',
                        isEmail: (val) => `${val} is not a valid email. `,
                      }}
                    />
                  </FormText>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Control.text
                      placeholder="Password"
                      type="password"
                      model=".password"
                      component={Input}
                    />
                  </InputGroup>
                  <FormText color="danger">
                    <Errors
                      model=".password"
                      show={{touched: true, focus: false}}
                      messages={{
                        isRequired: 'Password is required. ',
                      }}
                    />
                  </FormText>
                </FormGroup>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit" block>
                    Sign in
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

const mapStatesToProps = (state: ReducerState) => ({
})

const mapActionsToProps = {
  authLogin,
}

export default connect(mapStatesToProps, mapActionsToProps)(Login);
