import AdvancedSearchData from '../contracts/AdvancedSearchData';
import AdvancedSearchForm from '../contracts/AdvancedSearchForm';
import CollectionList from '../contracts/CollectionList';
import ReceiptDetail from '../contracts/ReceiptDetail';
import CollectionActionType from '../contracts/CollectionActionType';
import TablePagination from '../../utils/contracts/TablePagination';
import ReceiptData from '../contracts/ReceiptData';
import VerificationForm from '../contracts/VerificationForm';
import DropdownItem from '../contracts/DropdownItem';
import ExcelData from '../contracts/ExcelData';
import CsvData from '../contracts/CsvData';
import { type } from 'os';

export function advancedSearchData() {
  return { type: CollectionActionType.ADVANCED_SEARCH_DATA };
}
export function advancedSearchDataSuccess(
  advancedSearchData: AdvancedSearchData
) {
  return {
    type: CollectionActionType.ADVANCED_SEARCH_DATA_SUCCESS,
    advancedSearchData
  };
}
export function advancedSearchDataFailed() {
  return { type: CollectionActionType.ADVANCED_SEARCH_DATA_FAILED };
}

export function collectionList(
  pagination: TablePagination = new TablePagination(),
  advancedSearchForm: AdvancedSearchForm = new AdvancedSearchForm()
) {
  return {
    type: CollectionActionType.COLLECTION_LIST,
    pagination,
    advancedSearchForm
  };
}

export function collectionListSuccess(collectionList: CollectionList) {
  return { type: CollectionActionType.COLLECTION_LIST_SUCCESS, collectionList };
}

export function collectionListFailed() {
  return { type: CollectionActionType.COLLECTION_LIST_FAILED };
}

export function receiptData(receiptId: any) {
  return { type: CollectionActionType.RECEIPT_DATA, receiptId };
}

export function receiptDataSuccess(
  receiptDetail: ReceiptDetail,
  receiptData: ReceiptData
) {
  return {
    type: CollectionActionType.RECEIPT_DATA_SUCCESS,
    receiptDetail,
    receiptData
  };
}

export function receiptDataFailed() {
  return { type: CollectionActionType.RECEIPT_DATA_FAILED };
}

export function receiptDataReset() {
  return { type: CollectionActionType.RECEIPT_VERIFICATION_RESET };
}

export function tenantListData(mallId: any) {
  return { type: CollectionActionType.TENANT_LIST_DATA, mallId };
}

export function tenantListDataSuccess(tenantList: DropdownItem) {
  return { type: CollectionActionType.TENANT_LIST_DATA_SUCCESS, tenantList };
}

export function tenantListDataFailed() {
  return { type: CollectionActionType.TENANT_LIST_DATA_FAILED };
}

export function receiptVerification(
  receiptId: any,
  verificationForm: VerificationForm = new VerificationForm()
) {
  return {
    type: CollectionActionType.RECEIPT_VERIFICATION,
    receiptId,
    verificationForm
  };
}

export function receiptVerificationSuccess() {
  return { type: CollectionActionType.RECEIPT_VERIFICATION_SUCCESS };
}

export function receiptVerificationFail() {
  return { type: CollectionActionType.RECEIPT_VERIFICATION_FAIL };
}

export function receiptVerificationReset() {
  return { type: CollectionActionType.RECEIPT_VERIFICATION_RESET };
}

export function downloadExcel(advancedSearchForm: AdvancedSearchForm) {
  return { type: CollectionActionType.DOWNLOAD_EXCEL, advancedSearchForm };
}

export function downloadExcelSuccess(excelData: ExcelData) {
  return { type: CollectionActionType.DOWNLOAD_EXCEL_SUCCESS, excelData };
}

export function downloadExcelFailed() {
  return { type: CollectionActionType.DOWNLOAD_EXCEL_FAILED };
}

export function downloadCsv(advancedSearchForm: AdvancedSearchForm) {
  return { type: CollectionActionType.DOWNLOAD_CSV, advancedSearchForm };
}

export function downloadCsvSuccess(csvData: CsvData) {
  return { type: CollectionActionType.DOWNLOAD_CSV_SUCCESS, csvData };
}

export function downloadCsvFailed() {
  return { type: CollectionActionType.DOWNLOAD_CSV_FAILED };
}
