import * as React from 'react'
import { connect } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import LoadingState from '../contracts/LoadingState'
import { ReducerState } from '../../../../registrator'

interface ParentProps {
  loading: LoadingState
}

class Loading extends React.Component<ParentProps> {
  render() {
    const { loading } = this.props
    return (
      <Dialog
        open={loading.isOpen}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth='xs'
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'unset',
          },
        }}
      >
        <DialogContent
          style={{textAlign: 'center'}}
        >
          <CircularProgress />
        </DialogContent>
      </Dialog>
    )
  }
}


const mapStatesToProps = (state: ReducerState) => ({
  loading: state.loading
})

export default connect(mapStatesToProps)(Loading)