import { takeEvery, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import ResponseApi from '../../utils/contracts/ReponseApi';
import DashboardAction from '../contracts/DashboardAction';
import DashboardActionType from '../contracts/DashboardActionType';
import * as dashboardApi from '../api/dashboard';
import {
  mallTenantReportSuccess,
  mallTenantReportFailed,
  barChartSuccess,
  barChartFailed,
  pieChartSuccess,
  pieChartFailed
} from '../actions/dashboard';
import { showLoading, hideLoading } from "../../utils/loading/actions/loading"

function* mallTenantReport(action: DashboardAction) {
  let response: ResponseApi = yield dashboardApi.mallTenantReport(
    action.mallId,
    action.pagination,
    action.startDate,
    action.endDate
  )
  if (response.responseCode === 200) {
    yield put(hideLoading())
    yield put(mallTenantReportSuccess(response.responseData))
  }
  else {
    yield put(showLoading())
    yield put(mallTenantReportFailed())
    yield toast.error(response.responseMessage)
  }
}

function* barChart(action: DashboardAction) {
  let response: ResponseApi = yield dashboardApi.barChart(action.startDate, action.endDate)

  if (response.responseCode === 200) {
    yield put(hideLoading())
    yield put(barChartSuccess(response.responseData))
  } else {
    yield put(showLoading())
    yield put(barChartFailed())
    yield toast.error(response.responseMessage)
  }
}

function* pieChart(action: DashboardAction) {
  let response: ResponseApi = yield dashboardApi.pieChart(action.startDate, action.endDate)

  if (response.responseCode === 200) {
    yield put(hideLoading())
    yield put(pieChartSuccess(response.responseData))
  } else {
    yield put(showLoading())
    yield put(pieChartFailed())
    yield toast.error(response.responseMessage)
  }
}


export default [
  takeEvery(DashboardActionType.MALL_TENANT_REPORT, mallTenantReport),
  takeEvery(DashboardActionType.BAR_CHART, barChart),
  takeEvery(DashboardActionType.PIE_CHART, pieChart)
]