export enum CollectionActionType {
  ADVANCED_SEARCH_DATA = 'ADVANCED_SEARCH_DATA',
  ADVANCED_SEARCH_DATA_SUCCESS = 'ADVANCED_SEARCH_DATA_SUCCESS',
  ADVANCED_SEARCH_DATA_FAILED = 'ADVANCED_SEARCH_DATA_FAILED',
  COLLECTION_LIST = 'COLLECTION_LIST',
  COLLECTION_LIST_SUCCESS = 'COLLECTION_LIST_SUCCESS',
  COLLECTION_LIST_FAILED = 'COLLECTION_LIST_FAILED',
  RECEIPT_DATA = 'RECEIPT_DATA',
  RECEIPT_DATA_SUCCESS = 'RECEIPT_DATA_SUCCESS',
  RECEIPT_DATA_FAILED = 'RECEIPT_DATA_FAILED',
  TENANT_LIST_DATA = 'TENANT_LIST_DATA',
  TENANT_LIST_DATA_SUCCESS = 'TENANT_LIST_DATA_SUCCESS',
  TENANT_LIST_DATA_FAILED = 'TENANT_LIST_DATA_FAILED',
  RECEIPT_VERIFICATION = 'RECEIPT_VERIFICATION',
  RECEIPT_VERIFICATION_SUCCESS = 'RECEIPT_VERIFICATION_SUCCESS',
  RECEIPT_VERIFICATION_FAIL = 'RECEIPT_VERIFICATION_FAIL',
  RECEIPT_VERIFICATION_RESET = 'RECEIPT_VERIFICATION_RESET',

  DOWNLOAD_EXCEL = 'DOWNLOAD_COLLECTIONS_EXCEL',
  DOWNLOAD_EXCEL_SUCCESS = 'DOWNLOAD_COLLECTIONS_EXCEL_SUCCESS',
  DOWNLOAD_EXCEL_FAILED = 'DOWNLOAD_COLLECTIONS_EXCEL_FAILED',
  DOWNLOAD_CSV = 'DOWNLOAD_COLLECTIONS_CSV',
  DOWNLOAD_CSV_SUCCESS = 'DOWNLOAD_COLLECTIONS_CSV_SUCCESS',
  DOWNLOAD_CSV_FAILED = 'DOWNLOAD_COLLECTIONS_CSV_FAILED'
}

export default CollectionActionType;
