import React from "react";
import { connect } from "react-redux";
import { ReducerState } from "../../../../registrator";

import UserDahsboard from "../User/Container";

interface DerivedProps {
  roleCodes: Array<any>
}

interface States {
}

class Dashboard extends React.Component<DerivedProps, States> {
  render() {
    const { roleCodes } = this.props;
    
    return (
      roleCodes.indexOf('USER') !== -1
        ? (<UserDahsboard/>)
        : (<UserDahsboard/>)
    );
  }
}


const mapStatesToProps = (state: ReducerState) => ({
  roleCodes: state.auth.userData.roleCodes
})

const mapActionsToProps = {
}

export default connect(mapStatesToProps, mapActionsToProps)(Dashboard);
