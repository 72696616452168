import React from "react";
import { connect } from "react-redux";
import { Card, CardBody, Col, Row, Button } from "reactstrap";

import DateRangeFilter from "../../contracts/DateRangeFilter";
import { ReducerState } from "../../../../registrator";
import DateRangePicker from "../../../utils/components/DatePicker/DateRangePicker";
import { barChart, pieChart } from "../../actions/dashboard";
import DashboardState from "../../contracts/DashboardState";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { Link } from "react-router-dom";

interface DerivedProps {
  barChart: typeof barChart;
  pieChart: typeof pieChart;
  dashboard: DashboardState;
}

interface States {
  dateRange: DateRangeFilter;
}

class Chart extends React.Component<DerivedProps, States> {
  state: States = {
    dateRange: new DateRangeFilter(),
  };

  componentDidMount() {
    const { dateRange } = this.state;
    const { barChart, pieChart } = this.props;
    barChart(dateRange.startDate, dateRange.endDate);
    pieChart(dateRange.startDate, dateRange.endDate);
  }

  onDatesChange = ({ startDate, endDate }: any) => {
    const { barChart, pieChart } = this.props;
    this.setState((state: States) => ({
      dateRange: {
        ...state.dateRange,
        startDate,
        endDate,
      },
    }));
    barChart(startDate, endDate);
    pieChart(startDate, endDate);
  };

  onFocusChange = (focusedInput: any) => {
    this.setState((state: States) => ({
      dateRange: {
        ...state.dateRange,
        focusedInput,
      },
    }));
  };

  thousands_separators = (num: any) => {
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  };

  render() {
    const { dateRange } = this.state;
    const { dashboard } = this.props;

    const barValues = dashboard.bar.map((item) => {
      let get: any = item.data as any;
      let size = Math.max(get.valid, get.invalid, get.double_entry);
      return size;
    });
    const maxBarValue = Math.max(...barValues);

    const barData = dashboard.bar.map((item) => {
      let rename: any = {};
      let get: any = item.data as any;
      rename["Valid"] = +get.valid;
      rename["Invalid"] = +get.invalid;
      rename["Double Entry"] = +get.double_entry;
      let data = { ...rename, ...item.mall };
      return data;
    });

    return (
      <>
        <Row>
          <Col className="text-right mb-2">
            <DateRangePicker
              startDateId="CHART_START_DATE"
              startDate={dateRange.startDate}
              endDateId="CHART_END_DATE"
              endDate={dateRange.endDate}
              onDatesChange={this.onDatesChange}
              focusedInput={dateRange.focusedInput}
              onFocusChange={this.onFocusChange}
              numberOfMonths={1}
              isOutsideRange={() => false}
              readOnly
            />
          </Col>
        </Row>
        <Card className="shadow">
          <CardBody>
            <Row className="justify-content-end">
              <Col xs="12" className="text-center">
                <h1>
                  Report All Mall (
                  {dateRange.startDate &&
                    dateRange.startDate.format("DD MMM YYYY")}{" "}
                  -{" "}
                  {dateRange.endDate && dateRange.endDate.format("DD MMM YYYY")}
                  )
                </h1>
                <Col className="center overflow-bar">
                  <div style={{ height: "500px", width: "1000px" }}>
                    <ResponsiveBar
                      data={barData}
                      indexBy="name"
                      keys={["Valid", "Invalid", "Double Entry"]}
                      margin={{ top: 50, right: 100, bottom: 100, left: 100 }}
                      groupMode="grouped"
                      colors={{ scheme: "category10" }}
                      isInteractive={true}
                      animate={true}
                      motionStiffness={90}
                      motionDamping={15}
                      enableLabel={false}
                      maxValue={maxBarValue}
                      legends={[
                        {
                          dataFrom: "keys",
                          anchor: "bottom",
                          direction: "row",
                          justify: false,
                          translateX: 0,
                          translateY: 75,
                          itemsSpacing: 2,
                          itemWidth: 100,
                          itemHeight: 20,
                          itemDirection: "left-to-right",
                          itemOpacity: 0.85,
                          symbolSize: 20,
                          effects: [
                            {
                              on: "hover",
                              style: {
                                itemOpacity: 1,
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  </div>
                </Col>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Row>
          {dashboard.pie.map((item, index) => {
            let pieData = item.data.map((item) => ({
              ...item,
              id: item.label,
              value: +item.value,
            }));
            return (
              <Col key={index}>
                <Card className="shadow mt-4">
                  <CardBody>
                    <h1>
                      {item.mall.name} (
                      {dateRange.startDate &&
                        dateRange.startDate.format("DD MMM YYYY")}{" "}
                      -{" "}
                      {dateRange.endDate &&
                        dateRange.endDate.format("DD MMM YYYY")}
                      )
                    </h1>
                    <Col className="center overflow-pie">
                      <div style={{ height: "500px", width: "500px" }}>
                        <ResponsivePie
                          data={pieData}
                          margin={{
                            top: 50,
                            right: 150,
                            bottom: 50,
                            left: 150,
                          }}
                          padAngle={0.7}
                          cornerRadius={3}
                          colors={{ scheme: "category10" }}
                          borderWidth={1}
                          borderColor={{
                            from: "color",
                            modifiers: [["darker", 0.2]],
                          }}
                          radialLabel={function (label) {
                            return label.id + " (" + label.percentage + "%)";
                          }}
                          radialLabelsSkipAngle={10}
                          radialLabelsTextXOffset={6}
                          radialLabelsTextColor="#333333"
                          radialLabelsLinkOffset={0}
                          radialLabelsLinkDiagonalLength={24}
                          radialLabelsLinkHorizontalLength={6}
                          radialLabelsLinkStrokeWidth={1}
                          radialLabelsLinkColor={{ from: "color" }}
                          enableSlicesLabels={false}
                          slicesLabelsSkipAngle={10}
                          slicesLabelsTextColor="#333333"
                          animate={true}
                          motionStiffness={90}
                          motionDamping={15}
                          tooltip={({ id, value, amount }) => (
                            <div className="col">
                              <div className="row">
                                <strong>{id}</strong>
                              </div>
                              <div className="row">
                                Receipt: {value}
                                <br />
                                Amount: {this.thousands_separators(amount)}
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </Col>
                    <Button
                      className="mt-4"
                      size="sm"
                      color="primary"
                      tag={Link}
                      to={`/dashboard/${item.mall.id}/tenant-report`}
                    >
                      Tenant Report
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
      </>
    );
  }
}

const mapStatesToProps = (state: ReducerState) => ({
  dashboard: state.dashboard,
});

const mapActionsToProps = {
  barChart,
  pieChart,
};

export default connect(mapStatesToProps, mapActionsToProps)(Chart);
