import { Moment } from 'moment';

import TablePagination from '../../utils/contracts/TablePagination';
import ScoringList from '../contracts/ScoringList';
import ScoringActionType from '../contracts/ScoringActionType';
import ScoringDetail from '../contracts/ScoringDetail';
import ExcelData from '../contracts/ExcelData';
import CsvData from '../contracts/CsvData';

export function scoringList(
  startDate: Moment | null,
  endDate: Moment | null,
  pagination: TablePagination = new TablePagination()
) {
  return {
    type: ScoringActionType.SCORING_LIST,
    startDate,
    endDate,
    pagination
  };
}
export function scoringListSuccess(scoringList: ScoringList) {
  return { type: ScoringActionType.SCORING_LIST_SUCCESS, scoringList };
}
export function scoringListFailed() {
  return { type: ScoringActionType.SCORING_LIST_FAILED };
}

export function scoringDetail(
  scoringId: any,
  startDate: Moment | null,
  endDate: Moment | null,
  pagination: TablePagination = new TablePagination()
) {
  return {
    type: ScoringActionType.SCORING_DETAIL,
    scoringId,
    startDate,
    endDate,
    pagination
  };
}
export function scoringDetailSuccess(scoringDetail: ScoringDetail) {
  return { type: ScoringActionType.SCORING_DETAIL_SUCCESS, scoringDetail };
}
export function scoringDetailFailed() {
  return { type: ScoringActionType.SCORING_DETAIL_FAILED };
}

export function downloadExcel(
  startDate: Moment | null,
  endDate: Moment | null,
  search: string,
) {
  return {
    type: ScoringActionType.DOWNLOAD_EXCEL,
    startDate,
    endDate,
    search,
  };
}

export function downloadExcelSuccess(excelData: ExcelData) {
  return { type: ScoringActionType.DOWNLOAD_EXCEL_SUCCESS, excelData };
}

export function downloadExcelFailed() {
  return { type: ScoringActionType.DOWNLOAD_EXCEL_FAILED };
}

export function downloadCsv(
  startDate: Moment | null,
  endDate: Moment | null,
  search: string,
) {
  return {
    type: ScoringActionType.DOWNLOAD_CSV,
    startDate,
    endDate,
    search,
  };
}

export function downloadCsvSuccess(csvData: CsvData) {
  return { type: ScoringActionType.DOWNLOAD_CSV_SUCCESS, csvData };
}

export function downloadCsvFailed() {
  return { type: ScoringActionType.DOWNLOAD_CSV_FAILED };
}

