import * as React from "react";
import { connect } from "react-redux";

import { ReducerState } from "../../../registrator";
import { authLogout, authUserDetail } from "../actions/auth";
import { Redirect } from "react-router";
import AuthState from "../contracts/AuthState";

export default function AuthMiddleware(Component: any, roles: Array<string>) {
  interface DerivedProps {
    auth: AuthState;
    authLogout: typeof authLogout;
    authUserDetail: typeof authUserDetail;
  }

  class AuthMiddleware extends React.Component<DerivedProps> {
    componentDidMount() {
      const { auth, authLogout, authUserDetail } = this.props;

      if (!auth.isAuthenticated) {
        authLogout();
      }

      authUserDetail();
    }

    render() {
      if (!this.props.auth.isAuthenticated) {
        return <Redirect to="/auth/login" />;
      }

      return <Component {...this.props} />;
    }
  }

  const mapStatesToProps = (state: ReducerState) => ({
    auth: state.auth,
  });

  const mapActionsToProps = {
    authLogout,
    authUserDetail,
  };

  return connect(mapStatesToProps, mapActionsToProps)(AuthMiddleware);
}
