import { Moment } from 'moment';

export default class VerificationForm {
  tenant_id: any = null;
  mall_id: any = null;
  total: any = null;
  date: any = null;
  payment_type_id: any = null;
  card_number: any = null;
  status: any = null;
  reason_id: number = 0;
}
