export enum AuthActionType {
  AUTH_LOGIN = 'AUTH_LOGIN',
  AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS',
  AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED',

  AUTH_LOGOUT = 'AUTH_LOGOUT',

  AUTH_USER_DETAIL = 'AUTH_USER_DETAIL',
  AUTH_USER_DETAIL_DONE = 'AUTH_USER_DETAIL_DONE',
  AUTH_USER_DETAIL_FAIL = 'AUTH_USER_DETAIL_FAIL',

  AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN',
  AUTH_REFRESH_TOKEN_DONE = 'AUTH_REFRESH_TOKEN_DONE',
  AUTH_REFRESH_TOKEN_FAIL = 'AUTH_REFRESH_TOKEN_FAIL',
}

export default AuthActionType;