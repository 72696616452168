import React from "react";
import { connect } from "react-redux";
import {
  Col,
  Container,
  Row,
} from "reactstrap";
import Header from "../../../../components/Headers/Header";
import { ReducerState } from "../../../../registrator";
import ScoringList from "./ScoringList";

interface DerivedProps {
}

interface States {
}

class Scoring extends React.Component<DerivedProps, States> {
  render() {
    return (
      <>
        <Header
          breadcrumbs={[
          ]}
        />
        <Container className="mt--7" fluid>
          <Row>
            <Col xs="12" className="mt-4">
              <ScoringList />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}


const mapStatesToProps = (state: ReducerState) => ({
})

const mapActionsToProps = {
}

export default connect(mapStatesToProps, mapActionsToProps)(Scoring);
