import { fetchApi } from '../../utils/fetch';
import TablePagination from '../../utils/contracts/TablePagination';
import moment, { Moment } from 'moment';

export function scoringList(
  startDate: Moment | null,
  endDate: Moment | null,
  pagination: TablePagination = new TablePagination()
) {
  let queryString = `?page=${pagination.page}&size=${pagination.size}&search=${pagination.search}`;
  if (startDate && endDate) {
    queryString = `?page=${pagination.page}&size=${pagination.size}&search=${
      pagination.search
    }&startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format(
      'YYYY-MM-DD'
    )}`;
  }

  return fetchApi(`/scorings${queryString}`, {
    method: 'GET',
    headers: {
      Authorization: true
    }
  });
}

export function scoringDetail(
  scoringId: any,
  startDate: Moment | null,
  endDate: Moment | null,
  pagination: TablePagination = new TablePagination()
) {
  let queryString = `?page=${pagination.page}&size=${pagination.size}&search=${pagination.search}`;
  if (startDate && endDate) {
    queryString = `?page=${pagination.page}&size=${pagination.size}&search=${
      pagination.search
    }&startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(
      endDate
    ).format('YYYY-MM-DD')}`;
  }

  return fetchApi(`/scorings/${scoringId}${queryString}`, {
    method: 'GET',
    headers: {
      Authorization: true
    }
  });
}

export function downloadExcel(
  startDate: Moment | null,
  endDate: Moment | null,
  search: string,
) {
  const data = {
    startDate: '',
    endDate: '',
    search,
  };

  if (startDate) {
    data['startDate'] = startDate.format('YYYY-MM-DD'); 
  }

  if (endDate) {
    data['endDate'] = endDate.format('YYYY-MM-DD'); 
  }

  const queryString = new URLSearchParams(data).toString();

  return fetchApi(`/scorings/download/excel?${queryString}`, {
    method: 'GET',
    headers: {
      Authorization: true
    }
  });
}

export function downloadCsv(
  startDate: Moment | null,
  endDate: Moment | null,
  search: string,
) {
  const data = {
    startDate: '',
    endDate: '',
    search,
  };

  if (startDate) {
    data['startDate'] = startDate.format('YYYY-MM-DD'); 
  }

  if (endDate) {
    data['endDate'] = endDate.format('YYYY-MM-DD'); 
  }

  const queryString = new URLSearchParams(data).toString();

  return fetchApi(`/scorings/download/csv?${queryString}`, {
    method: 'GET',
    headers: {
      Authorization: true
    }
  });
}
