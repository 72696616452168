export default class UserData {
  public id: string|number = '';
  public email: string = '';
  public roles: Array<UserRoleData> = [];
  public roleCodes: Array<string> = [];
}

export class UserRoleData {
  public id: string|number = '';
  public name: string = '';
  public code: string = '';
}