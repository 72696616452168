import AuthActionType from "../contracts/AuthActionType";
import LoginForm from "../contracts/LoginForm";
import TokenData from "../contracts/TokenData";
import UserData from "../contracts/UserData";

export function authLogin(loginForm: LoginForm) {
  return { type: AuthActionType.AUTH_LOGIN, loginForm }
}
export function authLoginSuccess(tokenData: TokenData) {
  return { type: AuthActionType.AUTH_LOGIN_SUCCESS, tokenData }
}
export function authLoginFailed() {
  return { type: AuthActionType.AUTH_LOGIN_FAILED }
}

export function authLogout() {
  return { type: AuthActionType.AUTH_LOGOUT }
}

export function authUserDetail(shouldRefreshToken: boolean = true) {
  return { type: AuthActionType.AUTH_USER_DETAIL, shouldRefreshToken }
}
export function authUserDetailDone(userData: UserData) {
  return { type: AuthActionType.AUTH_USER_DETAIL_DONE, userData }
}
export function authUserDetailFail() {
  return { type: AuthActionType.AUTH_USER_DETAIL_FAIL }
}

export function authRefreshToken() {
  return { type: AuthActionType.AUTH_REFRESH_TOKEN }
}
export function authRefreshTokenDone(tokenData: TokenData) {
  return { type: AuthActionType.AUTH_REFRESH_TOKEN_DONE, tokenData }
}
export function authRefreshTokenFail() {
  return { type: AuthActionType.AUTH_REFRESH_TOKEN_FAIL }
}