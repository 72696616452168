import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
} from "reactstrap";
import DateRangePicker from "../../../utils/components/DatePicker/DateRangePicker";
import { ReducerState } from "../../../../registrator";
import Table from "../../../utils/components/Table/Table";
import TablePagination from "../../../utils/contracts/TablePagination";
import {
  scoringList,
  downloadExcel,
  downloadCsv,
} from "../../actions/scoring";
import DateRangeFilter from "../../contracts/DateRangeFilter";
import ScoringState from "../../contracts/ScoringState";
import moment from "moment";

interface DerivedProps {
  scoring: ScoringState;
  scoringList: typeof scoringList;
  downloadExcel: typeof downloadExcel;
  downloadCsv: typeof downloadCsv;
}

interface States {
  dateRange: DateRangeFilter;
}

class ScoringList extends React.Component<DerivedProps, States> {
  state: States = {
    dateRange: new DateRangeFilter(),
  };

  componentDidMount() {
    const { dateRange } = this.state;
    const { scoringList } = this.props;

    scoringList(dateRange.startDate, dateRange.endDate);
  }

  handlePageChange = (pagination: TablePagination) => {
    const { scoringList } = this.props;
    const { dateRange } = this.state;
    scoringList(dateRange.startDate, dateRange.endDate, pagination);
  };

  onDatesChange = ({ startDate, endDate }: any) => {
    const { scoringList } = this.props;
    this.setState((state: States) => ({
      dateRange: {
        ...state.dateRange,
        startDate,
        endDate,
      },
    }));
    scoringList(startDate, endDate);
  };

  onFocusChange = (focusedInput: any) => {
    this.setState((state: States) => ({
      dateRange: {
        ...state.dateRange,
        focusedInput,
      },
    }));
  };

  thousands_separators = (num: any) => {
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  };

  handleDownloadExcel = () => {
    const { downloadExcel, scoring } = this.props;
    const { dateRange } = this.state;

    const search = scoring.scoringList.pagination.search;

    downloadExcel(dateRange.startDate, dateRange.endDate, search);
  };

  handleDownloadCsv = () => {
    const { downloadCsv, scoring } = this.props;
    const { dateRange } = this.state;

    const search = scoring.scoringList.pagination.search;

    downloadCsv(dateRange.startDate, dateRange.endDate, search);
  };

  render() {
    const { scoring } = this.props;
    const { dateRange } = this.state;
    let startDate = dateRange.startDate
      ? moment(dateRange.startDate).format("DD MMM YYYY")
      : "";
    let endDate = dateRange.endDate
      ? moment(dateRange.endDate).format("DD MMM YYYY")
      : "";
    return (
      <>
        <Card className="shadow">
          <CardHeader className="border-0">
            <Row>
              <Col xs="12" className="align-self-center">
                <h2 className="mb-0 text-center">GEA Scoring</h2>
                <h3 className="text-center">
                  {startDate} - {endDate}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center flex-grow-1">
                <div>
                  <DateRangePicker
                    startDateId="GEA_SCORING_START_DATE"
                    startDate={dateRange.startDate}
                    endDateId="GEA_SCORING_END_DATE"
                    endDate={dateRange.endDate}
                    onDatesChange={this.onDatesChange}
                    focusedInput={dateRange.focusedInput}
                    onFocusChange={this.onFocusChange}
                    numberOfMonths={1}
                    minimumNights={0}
                    isOutsideRange={() => false}
                    readOnly
                  />
                </div>
              </div>

              <div>
                <Button
                  color="primary"
                  onClick={this.handleDownloadExcel}
                  className="mr-2"
                >
                  Download Excel
                </Button>
                <Button color="primary" onClick={this.handleDownloadCsv}>
                  Download CSV
                </Button>
              </div>
            </div>
            <Table
              config={{
                shouldShowSearch: true,
              }}
              pagination={scoring.scoringList.pagination}
              onSizeChange={this.handlePageChange}
              onSearchChange={this.handlePageChange}
              onPageChange={this.handlePageChange}
              Thead={
                <tr>
                  <th className="text-center" scope="col">
                    Location
                  </th>
                  <th className="text-center" scope="col">
                    GEA Operator
                  </th>
                  <th className="text-center" scope="col">
                    Total CCM
                  </th>
                  <th className="text-center" scope="col">
                    Total Receipts
                  </th>
                  <th className="text-center" scope="col">
                    Total Valid Receipts
                  </th>
                  <th className="text-center" scope="col">
                    Total Invalid Receipts
                  </th>
                  <th className="text-center" scope="col">
                    Total Valid Amount
                  </th>
                  <th className="text-center" scope="col">
                    Total Invalid Amount
                  </th>
                  <th className="text-center" scope="col">
                    Category
                  </th>
                  <th className="text-center" scope="col">
                    Action
                  </th>
                </tr>
              }
              Tbody={scoring.scoringList.items.map((item, index) => (
                <tr key={index}>
                  <td>{item.mall != null ? item.mall.name : ""}</td>
                  <td>{item.name != null ? item.name : ""}</td>
                  <td className="text-center">
                    {item.total_ccm != null
                      ? item.total_ccm
                      : ""}
                  </td>
                  <td className="text-center">
                    {item.total_trx != null
                      ? item.total_trx
                      : ""}
                  </td>
                  <td className="text-center">
                    {item.total_valid_trx != null
                      ? item.total_valid_trx
                      : ""}
                  </td>
                  <td className="text-center">
                    {item.total_invalid_trx != null
                      ? item.total_invalid_trx
                      : ""}
                  </td>
                  <td className="text-center">
                    {item.total_amount_trx != null
                      ? this.thousands_separators(item.total_amount_trx)
                      : ""}
                  </td>
                  <td className="text-center">
                    {item.total_invalid_amount_trx != null
                      ? this.thousands_separators(item.total_invalid_amount_trx)
                      : ""}
                  </td>
                  <td className="text-center">
                    {item.scoring_category != null ? item.scoring_category : ""}
                  </td>
                  <td className="text-center">
                    <Button
                      size="sm"
                      color="primary"
                      tag={Link}
                      to={`/dashboard/scorings/${item.id}/details/${dateRange.startDate}/${dateRange.endDate}`}
                    >
                      View
                    </Button>
                  </td>
                </tr>
              ))}
            />
          </CardBody>
          <CardFooter>
            <h5>Notes:</h5>
            <h5>Total Invalid Transaction Category:</h5>
            <Row>
              <Col xs="1">
                <table className="table table-sm table-borderless">
                  <tbody>
                    <tr>
                      <th scope="row">0 - 10</th>
                      <th>Low</th>
                    </tr>
                    <tr>
                      <th scope="row">11 - 50</th>
                      <th>Medium</th>
                    </tr>
                    <tr>
                      <th scope="row">{">"}50</th>
                      <th>High</th>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </>
    );
  }
}

const mapStatesToProps = (state: ReducerState) => ({
  scoring: state.scoring,
});

const mapActionsToProps = {
  scoringList,
  downloadExcel,
  downloadCsv,
};

export default connect(mapStatesToProps, mapActionsToProps)(ScoringList);
