import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects'
import { createForms } from 'react-redux-form';

/**
 * Register Modules Here.
 */

import AuthState from './modules/auth/contracts/AuthState';
import authReducer from './modules/auth/reducers/auth';
import authSaga from './modules/auth/saga/auth';

import DashboardState from './modules/dashboard/contracts/DashboardState';
import dashboardReducer from './modules/dashboard/reducers/dashboard';
import dashboardSaga from './modules/dashboard/saga/dashboard';

import CollectionState from './modules/collections/contracts/CollectionState';
import collectionReducer from './modules/collections/reducers/collection';
import collectionSaga from './modules/collections/saga/collection';

import ScoringState from './modules/scorings/contracts/ScoringState';
import scoringReducer from './modules/scorings/reducers/scoring';
import scoringSaga from './modules/scorings/saga/scoring';

import LoadingState from '../src/modules/utils/loading/contracts/LoadingState';
import loadingReducer from '../src/modules/utils/loading/reducers/loading';

export interface ReducerState {
  auth:AuthState
  dashboard:DashboardState,
  collection:CollectionState,
  scoring:ScoringState,
  loading:LoadingState
}

const reducers = combineReducers<any>(createForms({
  auth:authReducer,
  dashboard:dashboardReducer,
  collection:collectionReducer,
  scoring:scoringReducer,
  loading:loadingReducer
}));

function* sagas() {
  yield all([
    ...authSaga,
    ...dashboardSaga,
    ...collectionSaga,
    ...scoringSaga
  ])
}

export default {
  reducers,
  sagas
};