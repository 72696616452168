import store from '../../store';
import { AuthStorage } from '../auth/contracts/AuthStorage';
import { toast } from './components/Toast';
import { showLoading, hideLoading } from '../utils/loading/actions/loading';

interface Configs {}

export function fetchApi(
  uriPath: string,
  init: any = { headers: {} },
  configs: Configs = {}
) {
  return new Promise(resolve => {
    const defaultConfigs = {
      showFetchLoading: true,
      handleResponseCode400: true
    };
    configs = { ...defaultConfigs, ...configs };

    if (init.headers['Authorization']) {
      init.headers['Authorization'] = `Bearer ${localStorage.getItem(
        AuthStorage.TOKEN
      )}`;
    }

    store.dispatch(showLoading());
    fetch(`${process.env.REACT_APP_API_BASE_URL}${uriPath}`, init)
      .then(response => {
        response.json().then(function(res){
          if (res.responseCode === 200) {
              resolve(res);
              store.dispatch(hideLoading());
          } else if (res.responseCode === 401) {
            console.log('INVALID_TOKEN');
            resolve('INVALID_TOKEN');
            store.dispatch(hideLoading());
          } else {
            console.log(res);
            toast.error('Something error!');
            store.dispatch(hideLoading());
          }
        });
      })
      .catch(error => {
        console.log(error);
        toast.error('Something error!');
        store.dispatch(hideLoading());
      });
  });
}
