import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Collapse,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import DateRangePicker from '../../../utils/components/DatePicker/DateRangePicker';
import { ReducerState } from '../../../../registrator';
import { advancedSearchData, collectionList } from '../../actions/collection';
import CollectionState from '../../contracts/CollectionState';
import { Form, Control, actions } from 'react-redux-form';
import DateRangeFilter from '../../contracts/DateRangeFilter';
import AdvancedSearchForm from '../../contracts/AdvancedSearchForm';
import TablePagination from '../../../utils/contracts/TablePagination';
import store from '../../../../store';

interface DerivedProps {
  advancedSearchData: typeof advancedSearchData;
  collectionList: typeof collectionList;
  collection: CollectionState;
}

interface States {
  dateRange: DateRangeFilter;
  collapse: any;
  buttonIcon: any;
  dateRangeSubmitted: DateRangeFilter;
}

class AdvancedSearch extends React.Component<DerivedProps, States> {
  state: States = {
    dateRange: new DateRangeFilter(),
    collapse: false,
    buttonIcon: 'down',
    dateRangeSubmitted: new DateRangeFilter(),
  };

  componentDidMount() {
    const { advancedSearchData } = this.props;

    advancedSearchData();
  }

  toggleCollapse = () => {
    const { collapse } = this.state;
    this.setState({
      collapse: !collapse,
      buttonIcon: collapse ? 'down' : 'up'
    });
  };

  handleSearch = (advancedSearchForm: AdvancedSearchForm) => {
    const { collectionList } = this.props;
    collectionList(new TablePagination(), advancedSearchForm);
  };

  onDatesChangeSubmitted = ({ startDate, endDate }: any) => {
    this.setState((state: States) => ({
      dateRangeSubmitted: {
        ...state.dateRangeSubmitted,
        startDate,
        endDate
      }
    }));
    if (startDate) {
      store.dispatch(
        actions.change(
          'collection.advancedSearchForm.submitted_period_start',
          startDate.format('YYYY-MM-DD')
        )
      );
    }
    if (endDate) {
      store.dispatch(
        actions.change(
          'collection.advancedSearchForm.submitted_period_end',
          endDate.format('YYYY-MM-DD')
        )
      );
    }
  };

  onFocusChange = (focusedInput: any) => {
    this.setState((state: States) => ({
      dateRange: {
        ...state.dateRange,
        focusedInput
      }
    }));
  };

  onFocusChangeSubmitted = (focusedInput: any) => {
    this.setState((state: States) => ({
      dateRangeSubmitted: {
        ...state.dateRangeSubmitted,
        focusedInput
      }
    }));
  };

  render() {
    const { collection } = this.props;
    const { dateRange, collapse, buttonIcon, dateRangeSubmitted } = this.state;
    return (
      <>
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6" className="align-self-center">
                <h3 className="mb-0 text-left">Advanced Search</h3>
              </Col>
              <Col xs="6" className="text-right align-self-center">
                <Button
                  className="btn btn-link"
                  color="secondary"
                  onClick={this.toggleCollapse}
                >
                  <i className={`fas fa-chevron-${buttonIcon}`}></i>
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <Collapse isOpen={collapse}>
            <CardBody>
              <Form
                onSubmit={this.handleSearch}
                model="collection.advancedSearchForm"
              >
                <Row>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>Location</Label>
                      <Control
                        model=".location_id"
                        type="select"
                        component={Input}
                        defaultValue=""
                      >
                        <option value="">
                          All Location
                        </option>
                        {collection.advancedSearchData &&
                          collection.advancedSearchData.malls.map(
                            (mall, index) => (
                              <option key={index} value={mall.id}>
                                {mall.name}
                              </option>
                            )
                          )}
                      </Control>
                    </FormGroup>
                    <FormGroup>
                      <Label>Tenant</Label>
                      <Control
                        model=".tenant_id"
                        type="select"
                        component={Input}
                        defaultValue=""
                      >
                        <option value="">
                          All Tenant
                        </option>
                        {collection.advancedSearchData &&
                          collection.advancedSearchData.tenants.map(
                            (tenant, index) => (
                              <option key={index} value={tenant.id}>
                                {tenant.name}
                              </option>
                            )
                          )}
                      </Control>
                    </FormGroup>
                    <FormGroup>
                      <Label>Mall</Label>
                      <Control model=".mall_id" type="select" component={Input} defaultValue="">
                        <option value="">
                          All Mall
                        </option>
                        {collection.advancedSearchData &&
                          collection.advancedSearchData.malls.map(
                            (mall, index) => (
                              <option key={index} value={mall.id}>
                                {mall.name}
                              </option>
                            )
                          )}
                      </Control>
                    </FormGroup>
                    <FormGroup>
                      <Label>Member Name</Label>
                      <Control model=".member_name" component={Input} />
                    </FormGroup>
                    <FormGroup>
                      <Label>CCM</Label>
                      <Control model=".ccm" component={Input} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Payment Type</Label>
                      <Control
                        model=".payment_type_id"
                        type="select"
                        component={Input}
                        defaultValue=""
                      >
                        <option value="">
                          All Payment Type
                        </option>
                        {collection.advancedSearchData &&
                          collection.advancedSearchData.payment_types.map(
                            (payment_type, index) => (
                              <option key={index} value={payment_type.id}>
                                {payment_type.name}
                              </option>
                            )
                          )}
                      </Control>
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>Status</Label>
                      <Control
                        model=".status_id"
                        type="select"
                        component={Input}
                        defaultValue=""
                      >
                        <option value="">
                          All Status
                        </option>
                        {collection.advancedSearchData &&
                          collection.advancedSearchData.statuses.map(
                            (status, index) => (
                              <option key={index} value={status.id}>
                                {status.name}
                              </option>
                            )
                          )}
                      </Control>
                    </FormGroup>
                    <FormGroup>
                      <Label>Reason</Label>
                      <Control
                        model=".reason_id"
                        type="select"
                        component={Input}
                        defaultValue=""
                      >
                        <option value="">
                          All Reason
                        </option>
                        {collection.advancedSearchData &&
                          collection.advancedSearchData.reasons.map(
                            (reason, index) => (
                              <option key={index} value={reason.id}>
                                {reason.name}
                              </option>
                            )
                          )}
                      </Control>
                    </FormGroup>
                    <FormGroup>
                      <Label>Verified</Label>
                      <Control
                        model=".is_verified"
                        type="select"
                        component={Input}
                        defaultValue=""
                      >
                        <option value="">
                          All
                        </option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </Control>
                    </FormGroup>
                    <FormGroup>
                      <Label>Submitted Period</Label>
                      <div>
                        <DateRangePicker
                          startDateId="SUBMITTED_START_DATE"
                          startDate={dateRangeSubmitted.startDate}
                          endDateId="SUBMITTED_END_DATE"
                          endDate={dateRangeSubmitted.endDate}
                          onDatesChange={this.onDatesChangeSubmitted}
                          focusedInput={dateRangeSubmitted.focusedInput}
                          onFocusChange={this.onFocusChangeSubmitted}
                          numberOfMonths={1}
                          minimumNights={0}
                          isOutsideRange={() => false}
                          readOnly
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label>GEA Operator</Label>
                      <Control model=".operator_name" component={Input} />
                    </FormGroup>
                  </Col>
                  <Col xs="12">
                    <FormGroup className="text-right">
                      <Button color="primary">Search</Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Collapse>
        </Card>
      </>
    );
  }
}

const mapStatesToProps = (state: ReducerState) => ({
  collection: state.collection
});

const mapActionsToProps = {
  advancedSearchData,
  collectionList
};

export default connect(mapStatesToProps, mapActionsToProps)(AdvancedSearch);
