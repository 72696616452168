import { fetchApi } from '../../utils/fetch';
import TablePagination from '../../utils/contracts/TablePagination';
import querystring, { ParsedUrlQueryInput } from 'querystring';
import VerificationForm from '../contracts/VerificationForm';

export function advancedSearchData() {
  return fetchApi(`/receipts/advanced-search`, {
    method: 'GET',
    headers: {
      Authorization: true
    }
  });
}

export function collectionList(
  pagination: TablePagination,
  advancedSearchForm: any
) {
  let queryString = `?page=${pagination.page}&size=${
    pagination.size
  }&${querystring.stringify(advancedSearchForm)}`;
  return fetchApi(`/receipts${queryString}`, {
    method: 'GET',
    headers: {
      Authorization: true
    }
  });
}

export function receiptDetail(receiptId: any) {
  return fetchApi(`/receipts/${receiptId}`, {
    method: 'GET',
    headers: {
      Authorization: true
    }
  });
}

export function tenantList(mallId: any) {
  return fetchApi(`/master/tenants?mall_id=${mallId}`, {
    method: 'GET',
    headers: {
      Authorization: true
    }
  });
}

export function mallList() {
  return fetchApi(`/master/malls`, {
    method: 'GET',
    headers: {
      Authorization: true
    }
  });
}

export function paymentTypeList() {
  return fetchApi(`/master/payment-types`, {
    method: 'GET',
    headers: {
      Authorization: true
    }
  });
}

export function receiptStatusList() {
  return fetchApi(`/master/receipt-statuses`, {
    method: 'GET',
    headers: {
      Authorization: true
    }
  });
}

export function receiptReasonList() {
  return fetchApi(`/master/receipt-reasons`, {
    method: 'GET',
    headers: {
      Authorization: true
    }
  });
}

export function receiptVerification(
  receiptId: any,
  verificationForm: VerificationForm
) {
  return fetchApi(`/receipts/${receiptId}/verify`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: true
    },
    body: JSON.stringify(verificationForm)
  });
}

export function downloadExcel(advancedSearchForm: any) {
  let queryString = `?${querystring.stringify(advancedSearchForm)}`;

  return fetchApi(`/receipts/download/excel${queryString}`, {
    method: 'GET',
    headers: {
      Authorization: true
    }
  });
}

export function downloadCsv(advancedSearchForm: any) {
  let queryString = `?${querystring.stringify(advancedSearchForm)}`;

  return fetchApi(`/receipts/download/csv${queryString}`, {
    method: 'GET',
    headers: {
      Authorization: true
    }
  });
}
