import CollectionActionType from '../contracts/CollectionActionType';
import CollectionState from '../contracts/CollectionState';
import CollectionAction from '../contracts/CollectionAction';
import VerificationForm from '../contracts/VerificationForm';

import moment, { Moment } from 'moment';
import { receiptDetail } from '../api/collection';

const INITIAL_STATE: CollectionState = new CollectionState();

const handler = (state = INITIAL_STATE, action: CollectionAction) => {
  switch (action.type) {
    case CollectionActionType.ADVANCED_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        advancedSearchData: action.advancedSearchData
      };
    case CollectionActionType.COLLECTION_LIST_SUCCESS:
      return {
        ...state,
        collectionList: action.collectionList
      };
    case CollectionActionType.RECEIPT_DATA_SUCCESS:
      let date = moment(action.receiptDetail.date).format('YYYY-MM-DD')
      let datetime = moment(date + " " + action.receiptDetail.time).format('YYYY-MM-DD HH:mm:ss')
      
      action.receiptDetail.date = datetime
      let verificationForm = {
        ...new VerificationForm(),
        tenant_id: (action.receiptDetail.tenant) ? action.receiptDetail.tenant.id : 'INV_' + action.receiptDetail.location.name,
        mall_id: (action.receiptDetail.mall) ? action.receiptDetail.mall.id : 'INVALID',
        total: action.receiptDetail.total,
        date: datetime,
        card_number: action.receiptDetail.card_number,
        payment_type_id: (action.receiptDetail.payment_type) ? action.receiptDetail.payment_type.id : '',
        status: action.receiptDetail.status,
        reason_id: parseInt(action.receiptDetail.reason.id, 10),
        time: action.receiptDetail.time
      };
      return {
        ...state,
        receiptDetail: action.receiptDetail,
        receiptData: action.receiptData,
        verificationForm
      };
    case CollectionActionType.RECEIPT_VERIFICATION_RESET:
      return INITIAL_STATE;
    case CollectionActionType.TENANT_LIST_DATA_SUCCESS:
      return {
        ...state,
        receiptData: {
          ...state.receiptData,
          tenants: action.tenantList
        }
      };
    case CollectionActionType.RECEIPT_VERIFICATION_SUCCESS:
    case CollectionActionType.RECEIPT_VERIFICATION_RESET:
      return { ...state, verificationForm: new VerificationForm() };
    case CollectionActionType.DOWNLOAD_EXCEL_SUCCESS:
      return {
        ...state,
        excelData: action.excelData
      };
    case CollectionActionType.DOWNLOAD_CSV_SUCCESS:
      return {
        ...state,
        csvData: action.csvData
      };
    default:
      return state;
  }
};

export default handler;
