import React from "react";

import {
  Pagination,
  PaginationItem,
  PaginationLink,
  Table as TableBs,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import TablePagination from "../../contracts/TablePagination";

class Config {
  shouldShowSearch:boolean = true;
}
interface Props {
  config?:Config;
  pagination: TablePagination;
  onSizeChange: (pagination:TablePagination) => any;
  onSearchChange: (pagination:TablePagination) => any;
  onPageChange: (pagination:TablePagination) => any;
  Thead: any;
  Tbody: any;
}
interface PageItem {
  className:any;
  text:any;
  onClick:() => TablePagination;
}

class Table extends React.Component<Props> {
  static defaultProps = {
    config: new Config(),
  }
  
  getPageItems() {
    const { pagination, onPageChange } = this.props
    let pointer = 1;
    let itemSize = 3;
    let pageItems:Array<PageItem> = [];

    if (pagination.page > 1) {
      pointer = pagination.page - 1;
    }
    if (pagination.totalPage >= itemSize && pagination.page == pagination.totalPage) {
      pointer = pagination.page - 2;
    }

    while (pointer <= pagination.totalPage && pageItems.length < itemSize) {
      let page = pointer;
      pageItems.push({
        className: (pagination.page === pointer) ? 'active' : '',
        text: pointer,
        onClick: () => onPageChange({...pagination, page})
      });
      pointer++;
    }

    return pageItems;
  }
  
  render() {
    const { config, pagination, Thead, Tbody, onSizeChange, onSearchChange, onPageChange } = this.props
    const pageItems = this.getPageItems();
    const arrow = {
      doubleLeft: (pagination.page == 1) ? 'disabled' : '',
      left: (pagination.page == 1) ? 'disabled' : '',
      right: (pagination.page == pagination.totalPage) ? 'disabled' : '',
      doubleRight: (pagination.page == pagination.totalPage) ? 'disabled' : '',
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onSearchChange({...pagination, page: 1, search: e.currentTarget.value})
      }
    };

    return (
      <>
        <div className='table-component table-responsive'>
          <Row className="table-header my-2 mx-2 justify-content-between">
            <Col xs='12' sm='4' className='px-2'>
              <InputGroup size='sm' className='table-header-show'>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="bg-secondary">Show</InputGroupText>
                </InputGroupAddon>
                <Input
                  type="select"
                  onChange={(e) => onSizeChange({...pagination, page: 1, size: e.target.value})}
                >
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                </Input>
              </InputGroup>
            </Col>
            <Col xs='12' sm='8' className='px-2'>
              {(config && config.shouldShowSearch) && (
                <InputGroup size='sm' className='table-header-search'>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="bg-secondary">
                      Search
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    onKeyPress={(e) => handleKeyPress(e)}
                  />
                </InputGroup>
              )}
            </Col>
          </Row>
          <TableBs className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              {Thead}
            </thead>
            <tbody>
              {Tbody}
            </tbody>
          </TableBs>
          <Row className='table-footer py-4 mx-0'>
            <Col xs='12' md='6' className='my-2'>
              <div className='text-muted'>
                <small>Showing page {pagination.page} of {pagination.totalPage} from {pagination.totalItem} entries</small>
              </div>
            </Col>
            <Col xs='12' md='6' className='my-2'>
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="mb-0"
              >
                <PaginationItem className={arrow.doubleLeft}>
                  <PaginationLink
                    onClick={() => onPageChange({...pagination, page: 1})}
                    type='button'
                  >
                    <i className="fas fa-angle-double-left" />
                    <span className="sr-only">First</span>
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className={arrow.left}>
                  <PaginationLink
                    onClick={() => onPageChange({...pagination, page: (pagination.page - 1)})}
                    type='button'
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                
                {pageItems.map((pageItem:PageItem, index) => (
                  <PaginationItem key={index} className={pageItem.className}>
                    <PaginationLink
                      onClick={pageItem.onClick}
                      type='button'
                    >
                      {pageItem.text}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                
                <PaginationItem className={arrow.right}>
                  <PaginationLink
                    onClick={() => onPageChange({...pagination, page: (pagination.page + 1)})}
                    type='button'
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className={arrow.doubleRight}>
                  <PaginationLink
                    onClick={() => onPageChange({...pagination, page: (pagination.totalPage)})}
                    type='button'
                  >
                    <i className="fas fa-angle-double-right" />
                    <span className="sr-only">Last</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Table;
