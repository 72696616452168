import LoginContainer from "./modules/auth/components/Login/Container";
import DashboardContainer from "./modules/dashboard/components/Dashboard/Container";
import TenantContainer from "./modules/dashboard/components/User/Tenant";
import CollectionContainer from "./modules/collections/components/Collection/Container";
import VerificationContainer from "./modules/collections/components/Collection/Verification";
import ScoringContainer from "./modules/scorings/components/Scoring/Container";
import ScoringDetail from "./modules/scorings/components/Scoring/ScoringDetail";

import RouteItem from "./contracts/RouteItem";
import Menu from "./contracts/Menu";
import AuthMiddleware from "./modules/auth/middlewares/AuthMiddleware";
import GuestMiddleware from "./modules/auth/middlewares/GuestMiddleware";

export const menus: Array<Menu> = [
  {
    name: "Dashboard",
    icon: "fas fa-tachometer-alt",
    target: "/",
    roles: ["USER"],
  },
  {
    name: "Collections",
    icon: "fas fa-bars",
    target: "/dashboard/collections",
    roles: ["USER"],
  },
  {
    name: "Scoring",
    icon: "fas fa-star",
    target: "/dashboard/scorings",
    roles: ["USER"],
  },
];

export const routes: Array<RouteItem> = [
  { path: "/auth/login", Component: GuestMiddleware(LoginContainer) },
  {
    path: "/dashboard/",
    Component: AuthMiddleware(DashboardContainer, ["USER"]),
  },
  {
    path: "/dashboard/:mallId/tenant-report",
    Component: AuthMiddleware(TenantContainer, ["USER"]),
  },
  {
    path: "/dashboard/collections/:receiptId/verification",
    Component: AuthMiddleware(VerificationContainer, ["USER"]),
  },
  {
    path: "/dashboard/collections",
    Component: AuthMiddleware(CollectionContainer, ["USER"]),
  },
  {
    path: "/dashboard/scorings",
    Component: AuthMiddleware(ScoringContainer, ["USER"]),
  },
  {
    path: "/dashboard/scorings/:scoringId/details/:startDate/:endDate",
    Component: AuthMiddleware(ScoringDetail, ["USER"]),
  },
];

export default routes;
