import AdvancedSearchData from "./AdvancedSearchData";
import AdvancedSearchForm from "./AdvancedSearchForm";
import CollectionList from "./CollectionList";
import ReceiptDetail from "./ReceiptDetail";
import ReceiptData from "./ReceiptData";
import VerificationForm from "./VerificationForm";
import ExcelData from "./ExcelData";
import CsvData from "./CsvData";

export default class CollectionState {
  advancedSearchData: AdvancedSearchData = new AdvancedSearchData;
  advancedSearchForm: AdvancedSearchForm = new AdvancedSearchForm;
  collectionList: CollectionList = new CollectionList;
  receiptDetail: ReceiptDetail = new ReceiptDetail;
  receiptData: ReceiptData = new ReceiptData;
  verificationForm: VerificationForm = new VerificationForm;
  excelData: ExcelData = new ExcelData;
  csvData: CsvData = new CsvData;
}