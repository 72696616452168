import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody } from "reactstrap";
import { ReducerState } from "../../../../registrator";
import Table from "../../../utils/components/Table/Table";
import TablePagination from "../../../utils/contracts/TablePagination";
import {
  collectionList,
  downloadExcel,
  downloadCsv,
} from "../../actions/collection";
import CollectionState from "../../contracts/CollectionState";
import moment from "moment";
import Loading from "../../../utils/loading/components/Loading";

interface DerivedProps {
  collectionList: typeof collectionList;
  downloadExcel: typeof downloadExcel;
  downloadCsv: typeof downloadCsv;
  collection: CollectionState;
}

interface States { }

class CollectionList extends React.Component<DerivedProps, States> {
  componentDidMount() {
    const { collectionList } = this.props;

    collectionList();
  }

  handlePageChange = (pagination: TablePagination) => {
    const { collection, collectionList } = this.props;
    collectionList(pagination, collection.advancedSearchForm);
  };

  handleDownloadExcel = () => {
    const { collection, downloadExcel } = this.props;
    downloadExcel(collection.advancedSearchForm);
  };

  handleDownloadCsv = () => {
    const { collection, downloadCsv } = this.props;
    downloadCsv(collection.advancedSearchForm);
  };

  thousands_separators = (num: any) => {
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  };

  render() {
    const { collection } = this.props;
    return (
      <>
        <Loading />
        <Card className="shadow">
          <CardBody>
            <div>
              <Button
                color="primary"
                onClick={this.handleDownloadExcel}
                className="mr-2"
              >
                Download Excel
              </Button>
              <Button color="primary" onClick={this.handleDownloadCsv}>
                Download CSV
              </Button>
            </div>
            <Table
              config={{
                shouldShowSearch: false,
              }}
              pagination={collection.collectionList.pagination}
              onSizeChange={this.handlePageChange}
              onSearchChange={this.handlePageChange}
              onPageChange={this.handlePageChange}
              Thead={
                <tr>
                  <th className="text-center" scope="col">
                    Submitted On
                  </th>
                  <th className="text-center" scope="col">
                    Location
                  </th>
                  <th className="text-center" scope="col">
                    Tenants
                  </th>
                  <th className="text-center" scope="col">
                    Malls
                  </th>
                  <th className="text-center" scope="col">
                    Member Name
                  </th>
                  <th className="text-center" scope="col">
                    CCM
                  </th>
                  <th className="text-center" scope="col">
                    Transaction Date
                  </th>
                  <th className="text-center" scope="col">
                    Transaction Code
                  </th>
                  <th className="text-center" scope="col">
                    Total
                  </th>
                  <th className="text-center" scope="col">
                    Payment Type
                  </th>
                  <th className="text-center" scope="col">
                    Status
                  </th>
                  <th className="text-center" scope="col">
                    Reason
                  </th>
                  <th className="text-center" scope="col">
                    GEA Operator
                  </th>
                  <th className="text-center" scope="col">
                    Verifier
                  </th>
                  <th className="text-center" scope="col">
                    Action
                  </th>
                </tr>
              }
              Tbody={collection.collectionList.items.map((item, index) => (
                <tr key={index}>
                  <td className="text-center">
                    {moment(item.created_at).format("DD/MM/YYYY HH:mm:ss")}
                  </td>
                  <td>{item.location != null ? item.location.name : ""}</td>
                  <td>{item.tenant != null ? item.tenant.name : ""}</td>
                  <td>{item.mall != null ? item.mall.name : ""}</td>
                  <td>
                    {item.member != null ? item.member.name : ""} -{" "}
                    {item.member != null ? item.member.id : ""}
                  </td>
                  <td>{item.ccm}</td>
                  <td className="text-center">
                    {item.date != null
                      ? moment(item.date).format("DD/MM/YYYY")
                      : ""}
                  </td>
                  <td>{item.transaction_code}</td>
                  <td>
                    {item.total != null
                      ? this.thousands_separators(item.total)
                      : ""}
                  </td>
                  <td>
                    {item.payment_type != null ? item.payment_type.name : ""}
                  </td>
                  <td>{item.status}</td>
                  <td>{item.reason.name}</td>
                  <td>
                    {item.operator != null
                      ? item.operator.name
                      : "" && item.operator_verifying_at
                        ? `${item.operator != null ? item.operator.name : ""
                        } - ${moment(item.operator_verifying_at).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}`
                        : ""}
                  </td>
                  <td>
                    {item.verified_at && item.verifier
                      ? `${item.verifier != null ? item.verifier.fullname : ""
                      } - ${moment(item.verified_at).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}`
                      : ""}
                  </td>
                  <td className="text-center">
                    <Button
                      size="sm"
                      color="primary"
                      tag={Link}
                      to={`/dashboard/collections/${item.id}/verification`}
                    >
                      View
                    </Button>
                  </td>
                </tr>
              ))}
            />
          </CardBody>
        </Card>
      </>
    );
  }
}

const mapStatesToProps = (state: ReducerState) => ({
  collection: state.collection,
});

const mapActionsToProps = {
  collectionList,
  downloadExcel,
  downloadCsv,
};

export default connect(mapStatesToProps, mapActionsToProps)(CollectionList);
