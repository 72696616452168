import DashboardActionType from "../contracts/DashboardActionType"
import DashboardState from "../contracts/DashboardState"
import DashboardAction from "../contracts/DashboardAction"

const INITIAL_STATE:DashboardState = new DashboardState

const handler = (state = INITIAL_STATE, action:DashboardAction) => {
  switch (action.type) {
    case DashboardActionType.MALL_TENANT_REPORT_SUCCESS:
      return {
        ...state,
        tenant: {
          ...state.tenant,
          mall: action.tenantState.mall,
          tenants: action.tenantState.tenants
        }
      }
    case DashboardActionType.BAR_CHART_SUCCESS:
      return {
        ...state,
        bar: action.barChart
      }
      case DashboardActionType.PIE_CHART_SUCCESS:
      return {
        ...state,
        pie: action.pieChart
      }
    default:
        return state
  }
}

export default handler