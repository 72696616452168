import React from "react";
import { NavLink as NavLinkRRD, Link, RouteComponentProps, matchPath } from "react-router-dom";

import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";
import Menu from "../../contracts/Menu";
import { connect } from "react-redux";
import { ReducerState } from "../../registrator";

interface DerivedProps extends RouteComponentProps {
  roleCodes:Array<string>
  menus:Array<Menu>
  logo: {
    imgSrc:any
    imgAlt:string
    innerLink?:string
    outterLink?:string
  }
}

class Sidebar extends React.Component<DerivedProps> {
  state = {
    collapseOpen: false
  }

  constructor(props:DerivedProps) {
    super(props);
  }

  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  }

  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    });
  }

  createLinks = (menus:Array<Menu>) => {
    const { roleCodes } = this.props
    let result:Array<any> = []

    menus.forEach((menu, index) => {
      // Hide menu
      // let isHasMenuAccess = roleCodes.filter((roleCode) => menu.roles.indexOf(roleCode) > -1).length > 0
      // if (!isHasMenuAccess) return

      // Have submenus
      if (typeof menu.target == 'object') {
        let submenus = menu.target.filter((submenu, index) => {
          return roleCodes.filter((roleCode) => submenu.roles.indexOf(roleCode) > -1).length > 0
        })

        // Have more than 1 submenus
        if (submenus.length > 1) {
          result.push(
            <UncontrolledDropdown nav key={index}>
              <DropdownToggle nav className="nav-link-icon">
                <i className={`${menu.icon} mr-0`}/>
                {menu.name}
                <i className='ni ni-bold-down text-muted'
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: '.75em',
                  }}
                />
              </DropdownToggle>
              <DropdownMenu
                className="dropdown-menu-arrow"
                style={{left: '1.5em'}}
              >
                {submenus.map((submenu, index) => (
                  <DropdownItem
                    key={index}
                    to={submenu.target}
                    tag={Link}
                    onClick={this.closeCollapse}
                    style={{
                      display: 'block',
                      color: 'rgba(0, 0, 0, 0.5)'
                    }}
                  >
                    <i className={`mr-2 ${submenu.icon}`}/> {submenu.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
        // Only have 1 submenus, dont show dropdown
        else {
          result.push((
            <NavItem key={index}>
              <NavLink
                to={submenus[0].target}
                tag={NavLinkRRD}
                onClick={this.closeCollapse}
                activeClassName=''
              >
                <i className={menu.icon} />
                {menu.name}
              </NavLink>
            </NavItem>
          ))
        }
      }
      // No submenus
      else {
        result.push((
          <NavItem key={index}>
            <NavLink
              to={menu.target}
              tag={NavLinkRRD}
              onClick={this.closeCollapse}
              activeClassName=''
            >
              <i className={menu.icon} />
              {menu.name}
            </NavLink>
          </NavItem>
        ))
      }
    })

    return result
  }

  render() {
    const { menus, logo } = this.props

    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          {logo && (
            <NavbarBrand className="pt-0">
              <NavLinkRRD
                to="/"
              >
                <img
                  alt={logo.imgAlt}
                  className="navbar-brand-img"
                  src={logo.imgSrc}
                />
              </NavLinkRRD>
            </NavbarBrand>
          )}
          {/* User */}
          <Nav className="align-items-center d-md-none">
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={require("../../assets/img/theme/businessman.svg")}
                    />
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem divider />
                <Link to="/auth/login">
                  <DropdownItem>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo && (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                )}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Navigation */}
            <Nav navbar>
              {this.createLinks(menus)}
            </Nav>
            {/* Divider */}
            <hr className="my-3" />
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}
  
const mapStatesToProps = (state: ReducerState) => ({
  roleCodes: state.auth.userData.roleCodes
})

const mapActionsToProps = {
}

export default connect(mapStatesToProps, mapActionsToProps)(Sidebar)
