export enum DashboardActionType {
  MALL_TENANT_REPORT = 'MALL_TENANT_REPORT',
  MALL_TENANT_REPORT_SUCCESS = 'MALL_TENANT_REPORT_SUCCESS',
  MALL_TENANT_REPORT_FAILED = 'MALL_TENANT_REPORT_FAILED',
  BAR_CHART = 'BAR_CHART',
  BAR_CHART_SUCCESS = 'BAR_CHART_SUCCESS',
  BAR_CHART_FAILED = 'BAR_CHART_FAILED',
  PIE_CHART = 'PIE_CHART',
  PIE_CHART_SUCCESS = 'PIE_CHART_SUCCESS',
  PIE_CHART_FAILED = 'PIE_CHART_FAILED',
}

export default DashboardActionType;