import { fetchApi } from '../../utils/fetch'
import LoginForm from '../contracts/LoginForm';
import AuthStorage from '../contracts/AuthStorage';

export function authLogin(form:LoginForm) {
  return fetchApi(`/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: form.email,
      password: form.password,
    })
  });
}

export function authRefreshToken() {
  return fetchApi(`/auth/refresh-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      refresh_token: localStorage.getItem(AuthStorage.REFRESH_TOKEN),
    })
  });
}

export function authUserDetail() {
  return fetchApi(`/auth/profile`, {
    method: 'GET',
    headers: {
      'Authorization': true,
    },
  });
}