import React from "react";
import { connect } from "react-redux";
import {
  Col,
  Button,
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
} from "reactstrap";
import Header from "../../../../components/Headers/Header";
import { ReducerState } from "../../../../registrator";
import Table from "../../../utils/components/Table/Table";
import TablePagination from "../../../utils/contracts/TablePagination";
import { scoringDetail } from "../../actions/scoring";
import ScoringState from "../../contracts/ScoringState";
import moment from "moment";
import { RouteComponentProps } from "react-router";
import NumberFormat from "react-number-format";

interface DerivedProps
  extends RouteComponentProps<{
    scoringId: any;
    startDate: any;
    endDate: any;
  }> {
  scoringDetail: typeof scoringDetail;
  scoring: ScoringState;
}

interface States {}

class ScoringDetail extends React.Component<DerivedProps, States> {
  componentDidMount() {
    const { match, scoringDetail } = this.props;
    scoringDetail(
      match.params.scoringId,
      match.params.startDate,
      match.params.endDate
    );
  }

  handlePageChange = (pagination: TablePagination) => {
    const { match, scoringDetail } = this.props;
    scoringDetail(
      match.params.scoringId,
      match.params.startDate,
      match.params.endDate,
      pagination
    );
  };

  handleDownloadReceipt = () => {
    // const { collection, downloadExcel } = this.props;
    // downloadExcel(collection.advancedSearchForm);
  };

  render() {
    const { match, scoring } = this.props;
    let startDate = match.params.startDate
      ? moment(match.params.startDate).format("DD MMM YYYY")
      : "";
    let endDate = match.params.endDate
      ? moment(match.params.endDate).format("DD MMM YYYY")
      : "";

    return (
      <>
        <Header
          breadcrumbs={[
            { link: "/dashboard/scorings", text: "Scoring List" },
            {
              link: `/dashboard/scorings/${match.params.scoringId}/details/${match.params.startDate}/${match.params.endDate}`,
              text: "Scoring Detail",
            },
          ]}
        />
        <Container className="mt--7" fluid>
          <Row>
            <Col xs="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h2 className="mb-0 text-center">GEA Scoring Detail</h2>
                  {/* <p>{scoring.scoringDetail.mall.name}</p>
                  <p>{scoring.scoringDetail.operator.name}</p>
                  <p>{startDate} - {endDate}</p> */}

                  <Row>
                    <Col xs="1">
                      <table className="table table-sm table-borderless">
                        <tbody>
                          <tr>
                            <th scope="row">Location</th>
                            <th>
                              {scoring.scoringDetail.location != null
                                ? scoring.scoringDetail.location.name
                                : ""}
                            </th>
                          </tr>
                          <tr>
                            <th scope="row">GEA Operator</th>
                            <th>
                              {scoring.scoringDetail.operator != null
                                ? scoring.scoringDetail.operator.name
                                : ""}
                            </th>
                          </tr>
                          <tr>
                            <th scope="row">Period</th>
                            <th>
                              {startDate != null ? startDate : ""} -{" "}
                              {endDate != null ? endDate : ""}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Table
                    config={{
                      shouldShowSearch: true,
                    }}
                    pagination={scoring.scoringDetail.tenants.pagination}
                    onSizeChange={this.handlePageChange}
                    onSearchChange={this.handlePageChange}
                    onPageChange={this.handlePageChange}
                    Thead={
                      <tr>
                        <th className="text-center" scope="col">
                          Mall
                        </th>
                        <th className="text-center" scope="col">
                          Tenant
                        </th>
                        <th className="text-center" scope="col">
                          CCM
                        </th>
                        <th className="text-center" scope="col">
                          Submitted On
                        </th>
                        <th className="text-center" scope="col">
                          Transaction Amount (Rp)
                        </th>
                        <th className="text-center" scope="col">
                          Action
                        </th>
                      </tr>
                    }
                    Tbody={scoring.scoringDetail.tenants.items.map(
                      (item, index) => (
                        <tr key={index}>
                          <td className="text-left">
                            {item.mall != null ? item.mall.name : ""}
                          </td>
                          <td className="text-left">
                            {item.tenant != null ? item.tenant.name : ""}
                          </td>
                          <td className="text-left">
                            {item.ccm != null ? item.ccm : ""}
                          </td>
                          <td className="text-center">
                            {item.created_at != null
                              ? moment(item.created_at).format("DD/MM/YYYY")
                              : ""}
                          </td>
                          <td className="text-center">
                            <NumberFormat
                              value={item.total != null ? item.total : ""}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={""}
                            />
                          </td>
                          <td className="text-center">
                            {item.file_source ? (
                              <a target="_blank" href={item.file_source}>
                                <Button size="sm" color="primary">
                                  Download Receipt
                                </Button>
                              </a>
                            ) : (
                              <Button size="sm" color="primary" disabled>
                                Download Receipt
                              </Button>
                            )}
                          </td>
                        </tr>
                      )
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStatesToProps = (state: ReducerState) => ({
  scoring: state.scoring,
});

const mapActionsToProps = {
  scoringDetail,
};

export default connect(mapStatesToProps, mapActionsToProps)(ScoringDetail);
